<script>
import { useTheme } from '@/composable/Theme/useTheme';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent.vue';

export default {
  components: { FeedbackModalComponent },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },
};
</script>

<template>
  <FeedbackModalComponent
    :is-open="isOpen"
    :title="title"
    :image="background"
    :primary-call-to-action="() => $emit('on:close')"
    :primary-call-to-action-text="$t('buttons.ok')"
  />
</template>
