var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'EmptyStateComponent d-flex flex-column align-items-center justify-content-center p-5 emobg-border-radius-large',
    {
      'emobg-background-color-ground-lightest': _vm.hasBackground,
      'emobg-border-2 emobg-border-color-ground-light': _vm.hasBorder,
    },
  ],style:(_vm.hasBorder && 'border-style: dashed;'),attrs:{"data-test-id":"empty-state"}},[_c('div',{staticClass:"EmptyStateComponent__content d-flex flex-column align-center"},[(_vm.src || _vm.icon)?_c('div',[(_vm.src)?_c('figure',{staticClass:"mb-0"},[_c('img',{staticClass:"EmptyStateComponent__image",attrs:{"src":_vm.src,"alt":"empty state"}})]):(_vm.icon)?_c('ui-icon',{attrs:{"icon":_vm.icon,"size":_vm.iconSize}}):_vm._e()],1):_vm._e(),(_vm.title)?_c('h2',{staticClass:"emobg-font-large mt-4 mb-0",attrs:{"data-test-id":"title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.description || _vm.$slots.description)?_c('div',{staticClass:"emobg-color-ink-light mt-2",attrs:{"data-test-id":"description"}},[(_vm.description)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._t("description")],2):_vm._e()]),(_vm.action)?_c('div',{staticClass:"mt-3"},[_c('EmptyStateButtonComponent',_vm._b({staticClass:"EmptyStateComponent__action"},'EmptyStateButtonComponent',_vm.action,false))],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }