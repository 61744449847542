<script>
import { ICONS_SIZES } from '@emobg/web-components';
import EmptyStateButtonComponent from './EmptyStateButtonComponent';

export default {
  name: 'EmptyStateComponent',
  components: {
    EmptyStateButtonComponent,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: ICONS_SIZES.xLarge,
    },
    /**
     * {
     *   text: 'button or link text',
     *   to?: { name: route } || 'route'
     *   callback?: () => {}
     * }
     */
    action: {
      type: Object,
      default: undefined,
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <div
    :class="[
      'EmptyStateComponent d-flex flex-column align-items-center justify-content-center p-5 emobg-border-radius-large',
      {
        'emobg-background-color-ground-lightest': hasBackground,
        'emobg-border-2 emobg-border-color-ground-light': hasBorder,
      },
    ]"
    :style="hasBorder && 'border-style: dashed;'"
    data-test-id="empty-state"
  >
    <div class="EmptyStateComponent__content d-flex flex-column align-center">
      <div v-if="src || icon">
        <figure
          v-if="src"
          class="mb-0"
        >
          <img
            :src="src"
            alt="empty state"
            class="EmptyStateComponent__image"
          >
        </figure>
        <ui-icon
          v-else-if="icon"
          :icon="icon"
          :size="iconSize"
        />
      </div>
      <h2
        v-if="title"
        class="emobg-font-large mt-4 mb-0"
        data-test-id="title"
      >
        {{ title }}
      </h2>
      <div
        v-if="description || $slots.description"
        class="emobg-color-ink-light mt-2"
        data-test-id="description"
      >
        <p
          v-if="description"
          class="mb-0"
        >
          {{ description }}
        </p>
        <slot
          v-else
          name="description"
        />
      </div>
    </div>
    <div
      v-if="action"
      class="mt-3"
    >
      <EmptyStateButtonComponent
        v-bind="action"
        class="EmptyStateComponent__action"
      />
    </div>
  </div>
</template>

<style lang="scss">
.EmptyStateComponent{
  &__content {
    max-width: 450px;
  }

  &__image {
    height: 100%;
    max-height: 165px;
  }

  &__action {
    display: inline-block;
    min-width: 216px;

    .Ui-Button {
      width: 100%;
    }
  }
}
</style>
