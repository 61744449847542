<script>
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'EmptyStateButtonComponent',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: '',
    },
    to: {
      type: [Object, String],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: undefined,
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },
  computed: {
    testId() {
      return this.$attrs.dataTestId || 'action';
    },
  },
};
</script>

<template>
  <RouterLink
    v-if="to"
    :to="to"
    :data-test-id="testId"
  >
    <ui-button v-bind="fetchButtonSpecs()">
      {{ text }}
    </ui-button>
  </RouterLink>
  <ui-button
    v-else-if="callback"
    v-bind="fetchButtonSpecs()"
    :data-test-id="testId"
    :disabled="disabled"
    @clickbutton="callback"
  >
    {{ text }}
  </ui-button>
</template>
